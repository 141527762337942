import React from 'react';
import ReactDOM from 'react-dom';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

import AppRoute from './route';

import '../assets/stylesheets/application.scss'

library.add(fas);

ReactDOM.render( <AppRoute/>,  document.getElementById('root'));


