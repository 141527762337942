import {categoriesConstants} from '../_constants';

export function categories(state = {}, action) {
    switch (action.type) {
        case categoriesConstants.GETCATEGORIES_SUCCES:
            return action.payload;
        default:
            return state;
    }
}
