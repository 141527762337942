import React, {Component} from 'react';
import {famillesService} from "../../../../_services";
import CKEditor from 'ckeditor4-react';
import {history} from "../../../../_helpers";
import {updatePost} from "../../../../_actions";
import Placeholder from "../../../../../assets/images/placeholder.png"
import TextValidator from "../../../../_components/Validator/TextValidor";
import {ValidatorForm} from "react-form-validator-core";
import TextareaValidator from "../../../../_components/Validator/TextareaValidator";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Collapse} from "react-collapse";
import CkEditorValidator from "../../../../_components/Validator/CkEditorValidator";


class EditPostForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            title: props.post.title,
            content: props.post.content ? props.post.content : '',
            subtitle1: props.post.subtitle1 ? props.post.subtitle1 : '',
            subtitle2: props.post.subtitle2 ? props.post.subtitle2 : '',
            subtitle3: props.post.subtitle3 ? props.post.subtitle3 : '',
            subtitle4: props.post.subtitle4 ? props.post.subtitle4 : '',
            subtitle5: props.post.subtitle5 ? props.post.subtitle5 : '',
            paraf1: props.post.paraf1 ? props.post.paraf1 : '',
            paraf2: props.post.paraf2 ? props.post.paraf2 : '',
            paraf3: props.post.paraf3 ? props.post.paraf3 : '',
            paraf4: props.post.paraf4 ? props.post.paraf4 : '',
            paraf5: props.post.paraf5 ? props.post.paraf5 : '',
            meta_description: props.post.meta_description,
            famille_id: props.post.famille_id,
            familles: [
                {
                    id:0,
                    name:''
                }
            ],
            P1: true,
            P2: false,
            P3: false,
            P4: false,
            P5: false,
            submitted: false,
            file: props.post.image.url ? props.post.image.url : '',
            previousfile: props.post.image.url ? props.post.image.url : Placeholder
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeFile = this.handleChangeFile.bind(this);
    }

    componentDidMount() {
        famillesService.getAll().then(data => {
            this.setState({familles: data});
        });
    }

    handleChange(e) {
        const {name, value} = e.target;
        this.setState({[name]: value});
    }

    handleChangeFile(e){
        this.setState({
            previousfile: URL.createObjectURL(e.target.files[0]),
            file: e.target.files[0]
        })
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const data = {
            title: this.state.title,
            content: this.state.content,
            subtitle1: this.state.subtitle1,
            subtitle2: this.state.subtitle2,
            subtitle3: this.state.subtitle3,
            subtitle4: this.state.subtitle4,
            subtitle5: this.state.subtitle5,
            paraf1: this.state.paraf1,
            paraf2: this.state.paraf2,
            paraf3: this.state.paraf3,
            paraf4: this.state.paraf4,
            paraf5: this.state.paraf5,
            meta_description: this.state.meta_description,
            famille_id: this.state.famille_id,
            image: this.state.file
        };
        updatePost(data, this.props.post.id);
        history.push('/posts');
    }

    render() {

        const config = {
            toolbar: [['Bold', 'Italic'] , ['BulletedList', 'NumberedList', 'Link' ] , ['Undo', 'Redo'], ['Source']],
            entities_latin : false,
            language: 'fr'
        };

        return (
            <div className="row justify-content-md-center">
                <ValidatorForm name="form" className="col-8" onSubmit={this.handleSubmit}>
                    <h3>Titre : </h3>
                    <div className={'form-group'}>
                        <TextValidator
                            onChange={this.handleChange}
                            name="title"
                            value={this.state.title}
                            validators={['required']}
                            errorMessages={['Merci de remplir le champ Titre']}
                        />
                    </div>
                    <div className="form-group">
                        <h3>Image :</h3>
                        <input type="file" accept="image/png, image/jpeg" onChange={this.handleChangeFile}/>
                        <img src={this.state.previousfile} alt="" width="300" height="300" className="imagePost"/>

                    </div>
                    <h3> Chapeau : </h3>
                    <div className={'form-group'}>
                        <TextareaValidator
                            onChange={this.handleChange}
                            name="content"
                            value={this.state.content}
                            validators={['required']}
                            errorMessages={['Merci de remplir le champ Chapeau']}
                        />
                    </div>
                    <h3> Famille : </h3>
                    <div className={'form-group'}>
                        <select name="famille_id" value={this.state.famille_id} onChange={this.handleChange}>
                            {this.state.familles.map((famille) =>
                                <option key={famille.id} value={famille.id}>{famille.name}</option>
                            )}
                        </select>
                    </div>
                    <h3>Meta description : </h3>
                    <div className={'form-group'}>
                        <TextareaValidator
                            onChange={this.handleChange}
                            name="meta_description"
                            value={this.state.meta_description}
                            validators={['required']}
                            errorMessages={['Merci de remplir le champ Meta description']}
                        />
                    </div>

                    <h3>Contenue : </h3>
                    <br/>
                    <h4><button type="button" className="collapse-btn" onClick={() => this.setState({P1: !this.state.P1})}><FontAwesomeIcon icon={this.state.P1? 'angle-down' : 'angle-right'} /></button> Paragraphe 1 : </h4>
                    <Collapse isOpened={this.state.P1}>
                    <div className={'form-group'}>
                        <label>Titre</label>
                        <TextValidator
                            onChange={this.handleChange}
                            name="subtitle1"
                            value={this.state.subtitle1}
                            validators={['required']}
                            errorMessages={['Merci de remplir le champ Titre']}
                        />
                    </div>
                    <div className={'form-group'}>
                        <label >Paragraphe</label>
                        <CkEditorValidator
                            type="classic"
                            config={config}
                            data={this.state.paraf1}
                            value={this.state.paraf1}
                            onChange={(e) => {
                                this.setState({paraf1: e.editor.getData() });
                            }}
                            onBeforeLoad={ ( CKEDITOR ) => ( CKEDITOR.disableAutoInline = true ) }
                            validators={['required']}
                            errorMessages={['Merci de remplir le champ Paragraphe']}
                        />
                    </div>
                    </Collapse>
                    <hr/>
                    <h4><button type="button" className="collapse-btn" onClick={() => this.setState({P2: !this.state.P2})}><FontAwesomeIcon icon={this.state.P2? 'angle-down' : 'angle-right'} /></button> Paragraphe 2 : </h4>
                    <Collapse isOpened={this.state.P2}>
                    <div className={'form-group'}>
                        <label >Titre</label>
                        <input type="text" className="form-control" name="subtitle2" value={this.state.subtitle2}
                               onChange={this.handleChange}/>
                    </div>
                    <div className={'form-group'}>
                        <label >Paragraphe</label>
                        <CKEditor
                            type="classic"
                            config={config}
                            data={this.state.paraf2}
                            onChange={(e) => {
                                this.setState({paraf2: e.editor.getData() });
                            }}
                        />
                    </div>
                    </Collapse>
                    <hr/>
                    <h4><button type="button" className="collapse-btn" onClick={() => this.setState({P3: !this.state.P3})}><FontAwesomeIcon icon={this.state.P3? 'angle-down' : 'angle-right'} /></button> Paragraphe 3 : </h4>
                    <Collapse isOpened={this.state.P3}>
                    <div className={'form-group'}>
                        <label >Titre</label>
                        <input type="text" className="form-control" name="subtitle3" value={this.state.subtitle3}
                               onChange={this.handleChange}/>
                    </div>
                    <div className={'form-group'}>
                        <label >Paragraphe</label>
                        <CKEditor
                            type="classic"
                            config={config}
                            data={this.state.paraf3}
                            onChange={(e) => {
                                this.setState({paraf3: e.editor.getData() });
                            }}
                        />
                    </div>
                    </Collapse>
                    <hr/>
                    <h4><button type="button" className="collapse-btn" onClick={() => this.setState({P4: !this.state.P4})}><FontAwesomeIcon icon={this.state.P4? 'angle-down' : 'angle-right'} /></button> Paragraphe 4 : </h4>
                    <Collapse isOpened={this.state.P4}>
                    <div className={'form-group'}>
                        <label >Titre</label>
                        <input type="text" className="form-control" name="subtitle4" value={this.state.subtitle4}
                               onChange={this.handleChange}/>
                    </div>
                    <div className={'form-group'}>
                        <label >Paragraphe</label>
                        <CKEditor
                            type="classic"
                            config={config}
                            data={this.state.paraf4}
                            onChange={(e) => {
                                this.setState({paraf4: e.editor.getData() });
                            }}
                        />
                    </div>
                    </Collapse>
                    <hr/>
                    <h4><button type="button" className="collapse-btn" onClick={() => this.setState({P5: !this.state.P5})}><FontAwesomeIcon icon={this.state.P5? 'angle-down' : 'angle-right'} /></button> Paragraphe 5 : </h4>
                    <Collapse isOpened={this.state.P5}>
                    <div className={'form-group'}>
                        <label >Titre</label>
                        <input type="text" className="form-control" name="subtitle5" value={this.state.subtitle5}
                               onChange={this.handleChange}/>
                    </div>
                    <div className={'form-group'}>
                        <label >Paragraphe</label>
                        <CKEditor
                            type="classic"
                            config={config}
                            data={this.state.paraf5}
                            onChange={(e) => {
                                this.setState({paraf5: e.editor.getData() });
                            }}
                        />
                    </div>
                    </Collapse>
                    <hr/>
                    <div className="form-group text-center">
                        <button className="btn btn-primary">Enregistrer</button>
                    </div>
                </ValidatorForm>
            </div>
        );
    }
}


export default (EditPostForm);





