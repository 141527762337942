import {postsConstants} from '../_constants';

export function posts(state = [], action) {
    switch (action.type) {
        case postsConstants.GETPOSTS_SUCCES:
            return action.payload;
        case postsConstants.GETPOST_SUCCES:
            return [action.payload];
        case postsConstants.UPDATEPOST_SUCCES:
            return action.payload;
        default:
            return state;
    }
}
