import React from 'react';

export const AddPostHeader = (props) => {
    return(
        <div className="header">
            <h2>Ajouter un article</h2>
            <hr/>
        </div>
    )
};
