import React from 'react';
import { ValidatorComponent } from 'react-form-validator-core';

class TextValidator extends ValidatorComponent {

    render() {
        const { errorMessages, validators, requiredError, validatorListener, ...rest } = this.props;

        return (
            <>
                <input type="text" className="form-control"
                    {...rest}
                    ref={(r) => { this.input = r; }}
                />
                {this.errorText()}
            </>
        );
    }

    errorText() {
        const { isValid } = this.state;

        if (isValid) {
            return null;
        }

        return (
            <p style={{ color: 'red' }}>
                {this.getErrorMessage()}
            </p>
        );
    }
}

export default TextValidator;
