import {geosService} from "../_services";
import {geosConstants} from "../_constants";

export function getGeo(id) {
    const promise = geosService.getGeo(id);

    return {
        type: geosConstants.GETGEO_SUCCES,
        payload: promise
    };
}

export function updateGeo(data, id) {
    const promise = geosService.update(data, id);
    return {
        type: geosConstants.UPDATEGEO_SUCCES,
        payload: promise
    };
}
