import {fetchAPI} from "./API"

export const geosService = {
    getGeo,
    update,
    getStatGeoTop20,
};

function getGeo(id) {
    return fetchAPI("geos/"+id, "GET")
}

function update(data, id) {
    return fetchAPI("geos/"+id, "PUT", data)
}

function getStatGeoTop20() {
    return fetchAPI("geos_no_des", "GET")
}
