import {authHeader, authHeaderFormData} from '../_helpers';

export function fetchAPI(url, method, body, callback){
    const good_url = `${process.env.API_URL}/${url}`;
    const requestOptions = {
        method: method.toUpperCase(),
        headers: authHeader(),
        body: JSON.stringify(body),
    };
    const promise = fetch(good_url, requestOptions)
        .then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    // auto logout if 401 response returned from api
                    logout();
                    location.reload(true);
                }
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
            return response.json()})
        .then(callback);
    return promise
}

export function fetchAPIFormData(url, method, body){
    const good_url = `${process.env.API_URL}/${url}`
    const requestOptions = {
        method: method.toUpperCase(),
        headers: authHeaderFormData(),
        body: body
    }
    const promise = fetch(good_url, requestOptions)
        .then(response => response.json());
    return promise
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}
