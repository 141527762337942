import React, {Component} from 'react';


class GeosTable extends Component {


    render() {

        return (
            <div className="row justify-content-md-center">
            </div>
        );
    }
}


export default GeosTable;





