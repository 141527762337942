import React from "react";

import {Switch, Route, Router} from "react-router-dom";
import {Provider} from "react-redux";

import {history , store} from "./_helpers";

import { PrivateRoute } from './_components';
import { Login } from "./_containers/Auth/Login";
import Posts from "./_containers/Posts/Posts";
import Stats from "./_containers/Stats/Stats";
import Geos from "./_containers/Geo/Geos";
import AddPost from "./_containers/Posts/Post/AddPost/AddPost";
import EditPost from "./_containers/Posts/Post/EditPost/EditPost";
import ShowPost from "./_containers/Posts/Post/ShowPost/ShowPost";
import ShowGeo from "./_containers/Geo/ShowGeo/ShowGeo";
import EditGeo from "./_containers/Geo/EditGeo/EditGeo";



function AppRoute() {
    return (
        <Provider store={store}>
            <Router history={history}>
                <Switch>
                    <PrivateRoute exact path="/" component={Stats}/>
                    <PrivateRoute exact path="/posts" component={Posts}/>
                    <PrivateRoute exact path="/post/:id" component={ShowPost}/>
                    <PrivateRoute exact path="/edit/post/:id" component={EditPost}/>
                    <PrivateRoute exact path="/add/post" component={AddPost}/>
                    <PrivateRoute exac path="/geos" component={Geos}/>
                    <PrivateRoute exac path="/geo/:id" component={ShowGeo}/>
                    <PrivateRoute exac path="/edit/geo/:id" component={EditGeo}/>
                    <Route exact path="/login" component={Login}/>
                </Switch>
            </Router>
        </Provider>
    );
}

export default AppRoute;
