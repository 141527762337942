import React from 'react';
import{Link} from "react-router-dom";

export const ShowGeoHeader = (props) => {
    return(
        <div className="header row">
            <div className="col-lg-10">
                <h2>{props.title}</h2>
            </div>
            <div className="col-lg-2 text-right">
                <Link to={"/edit/geo/"+props.id}><button className="btn btn-primary">Edition</button></Link>
            </div>
        </div>
    )
};
