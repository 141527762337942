import React from 'react';

export const EditPostHeader = (props) => {
    return(
        <div className="header">
            <h2>Edition d'article</h2>
            <h4>{props.title}</h4>
            <hr/>
        </div>
    )
};
