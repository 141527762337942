import React, {Component} from 'react';

import Layout from "../../../../_components/Layout/Layout";
import {EditPostHeader} from "./EditPostHeader";
import EditPostForm from "./EditPostForm";
import {connect} from "react-redux";
import { bindActionCreators } from 'redux';
import {getPost} from "../../../../_actions";


class EditPost extends Component {

    componentDidMount(){
        if (!this.props.post) {
            this.props.getPost(this.props.match.params.id);
        }
    }

    render() {
        if(!this.props.post){
            return <p>Chargmement ... </p>
        }
        return (
            <Layout>
                <div className="col-lg-10">
                    <EditPostHeader
                        title={this.props.post.title}
                    />
                    <EditPostForm
                        post={this.props.post}
                    />

                </div>
            </Layout>
        );
    }
}

function mapStateToProps(state, ownProps) {
    const id = parseInt(ownProps.match.params.id, 10);
    const post = state.posts.find(post => post.id === id);
    return {post};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getPost }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(EditPost);





