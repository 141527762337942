import React from 'react';

export const EditGeoHeader = (props) => {
    return(
        <div className="header">
            <h2>Edition de la Geo</h2>
            <h4>{props.title}</h4>
            <hr/>
        </div>
    )
};
