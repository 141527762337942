import React, {Component} from 'react';

import Layout from "../../../_components/Layout/Layout";
import {connect} from "react-redux";
import { bindActionCreators } from 'redux';
import {getGeo} from "../../../_actions";
import {ShowGeoHeader} from "./ShowGeoHeader";


class ShowGeo extends Component {

    componentDidMount(){
        if (!this.props.geos) {
            this.props.getGeo(this.props.match.params.id);
        }
    }

    render() {
        if(!this.props.geo){
            return (<p>Chargmement ... </p>)
        }
        return (
            <Layout>
                <div className="col-lg-10">
                    <ShowGeoHeader
                        title={this.props.geo.nom}
                        id={this.props.geo.id}
                    />
                    <hr/>
                    <br/>
                    <h2>{this.props.geo.h2}</h2>
                    <p>{this.props.geo.paraf1}</p>
                    <h2>Lire la suite : </h2>
                    <h3>{this.props.geo.h3_1}</h3>
                    <p dangerouslySetInnerHTML={{__html: this.props.geo.paraf2}} />
                    <h3>{this.props.geo.h3_2}</h3>
                    <p dangerouslySetInnerHTML={{__html: this.props.geo.paraf3}} />
                    <h3>{this.props.geo.h3_3}</h3>
                    <p dangerouslySetInnerHTML={{__html: this.props.geo.paraf4}} />
                </div>
            </Layout>
        );
    }
}

function mapStateToProps(state, ownProps) {
    const id = parseInt(ownProps.match.params.id, 10);
    const geo = state.geos.find(geo => geo.id === id);
    return {geo};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getGeo }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(ShowGeo);





