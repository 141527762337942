import React, {Component} from 'react';

import Layout from "../../../_components/Layout/Layout";
import {EditGeoHeader} from "./EditGeoHeader";
import EditPostForm from "./EditGeoForm";
import {connect} from "react-redux";
import { bindActionCreators } from 'redux';
import {getGeo} from "../../../_actions";


class EditGeo extends Component {

    componentDidMount(){
        if (!this.props.geos) {
            this.props.getGeo(this.props.match.params.id);
        }
    }

    render() {
        if(!this.props.geo){
            return <p>Chargmement ... </p>
        }
        return (
            <Layout>
                <div className="col-lg-10">
                    <EditGeoHeader
                        title={this.props.geo.nom}
                    />
                    <EditPostForm
                        geo={this.props.geo}
                    />

                </div>
            </Layout>
        );
    }
}

function mapStateToProps(state, ownProps) {
    const id = parseInt(ownProps.match.params.id, 10);
    const geo = state.geos.find(geo => geo.id === id);
    return {geo};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getGeo }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(EditGeo);





