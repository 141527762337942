import React, {Component} from 'react';
import CKEditor from 'ckeditor4-react';
import {history} from "../../../_helpers";
import {updateGeo} from "../../../_actions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Collapse} from "react-collapse";


class EditGeoForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            h2: props.geo.h2 ? props.geo.h2 : '',
            paraf1: props.geo.paraf1 ? props.geo.paraf1 : '',
            h3_1: props.geo.h3_1 ? props.geo.h3_1 : '',
            paraf2: props.geo.paraf2 ? props.geo.paraf2 : '',
            h3_2: props.geo.h3_2 ? props.geo.h3_2 : '',
            paraf3: props.geo.paraf3 ? props.geo.paraf3 : '',
            h3_3: props.geo.h3_3 ? props.geo.h3_3 : '',
            paraf4: props.geo.paraf4 ? props.geo.paraf4 : '',
            submitted: false,
            P1: false,
            P2: false,
            P3: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
    }

    handleChange(e) {
        const {name, value} = e.target;
        this.setState({[name]: value});
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const data = {
            h2: this.state.h2,
            paraf1: this.state.paraf1,
            h3_1: this.state.h3_1,
            paraf2: this.state.paraf2,
            h3_2: this.state.h3_2,
            paraf3: this.state.paraf3,
            h3_3: this.state.h3_3,
            paraf4: this.state.paraf4,
            description: true
        };
        updateGeo(data, this.props.geo.id);
        history.push('/geos');
    }

    render() {

        const config = {
            toolbar: [['Bold', 'Italic'] , ['BulletedList', 'NumberedList', 'Link' ] , ['Undo', 'Redo'], ['Source']],
            entities_latin : false,
            language: 'fr'
        };

        return (
            <div className="row justify-content-md-center">
                <form name="form" className="col-8" onSubmit={this.handleSubmit}>
                    <h3>Titre : </h3>
                    <div className={'form-group'}>
                        <input type="text" className="form-control" name="h2" value={this.state.h2}
                               onChange={this.handleChange}/>
                    </div>
                    <h3> Chapeau : </h3>
                    <div className={'form-group'}>
                        <textarea className="form-control" name="paraf1" value={this.state.paraf1} rows="5"
                                  onChange={this.handleChange}> </textarea>
                    </div>

                    <h3>Contenue : </h3>
                    <br/>
                    <h4><button type="button" className="collapse-btn" onClick={() => this.setState({P1: !this.state.P1})}><FontAwesomeIcon icon={this.state.P1? 'angle-down' : 'angle-right'} /></button> Paragraphe 1 : </h4>
                    <Collapse isOpened={this.state.P1}>
                    <div className={'form-group'}>
                        <label>Titre</label>
                        <input type="text" className="form-control" name="h3_1" value={this.state.h3_1}
                               onChange={this.handleChange}/>
                    </div>
                    <div className={'form-group'}>
                        <label>Paragraphe</label>
                        <CKEditor
                            type="classic"
                            config={config}
                            data={this.state.paraf2}
                            onBeforeLoad={ ( CKEDITOR ) => ( CKEDITOR.disableAutoInline = true ) }
                            onChange={(e) => {
                                this.setState({paraf2: e.editor.getData() });
                            }}
                        />
                    </div>
                    </Collapse>
                    <hr/>
                    <h4><button type="button" className="collapse-btn" onClick={() => this.setState({P2: !this.state.P2})}><FontAwesomeIcon icon={this.state.P2? 'angle-down' : 'angle-right'} /></button> Paragraphe 2 : </h4>
                    <Collapse isOpened={this.state.P2}>
                    <div className={'form-group'}>
                        <label>Titre</label>
                        <input type="text" className="form-control" name="h3_2" value={this.state.h3_2}
                               onChange={this.handleChange}/>
                    </div>
                    <div className={'form-group'}>
                        <label>Paragraphe</label>
                        <CKEditor
                            type="classic"
                            config={config}
                            data={this.state.paraf3}
                            onChange={(e) => {
                                this.setState({paraf3: e.editor.getData() });
                            }}
                        />
                    </div>
                    </Collapse>
                    <hr/>
                    <h4><button type="button" className="collapse-btn" onClick={() => this.setState({P3: !this.state.P3})}><FontAwesomeIcon icon={this.state.P3? 'angle-down' : 'angle-right'} /></button> Paragraphe 3 : </h4>
                    <Collapse isOpened={this.state.P3}>
                    <div className={'form-group'}>
                        <label>Titre</label>
                        <input type="text" className="form-control" name="h3_3" value={this.state.h3_3}
                               onChange={this.handleChange}/>
                    </div>
                    <div className={'form-group'}>
                        <label>Paragraphe</label>
                        <CKEditor
                            type="classic"
                            config={config}
                            data={this.state.paraf4}
                            onChange={(e) => {
                                this.setState({paraf4: e.editor.getData() });
                            }}
                        />
                    </div>
                    </Collapse>
                    <hr/>
                    <div className="form-group text-center">
                        <button className="btn btn-primary">Enregistrer</button>
                    </div>
                </form>
            </div>
        );
    }
}


export default (EditGeoForm);





