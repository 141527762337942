import {postsConstants} from "../_constants";
import {postsService} from "../_services";

export function getPosts() {
    const promise = postsService.getAll();

    return {
        type: postsConstants.GETPOSTS_SUCCES,
        payload: promise
    };
}

export function getPost(id) {
    const promise = postsService.getPost(id);

    return {
        type: postsConstants.GETPOST_SUCCES,
        payload: promise
    };
}

export function createPost(data) {
    const promise = postsService.create(data);
    return {
        type: postsConstants.CREATEPOST_SUCCES,
        payload: promise
    };
}

export function updatePost(data, id) {
    const promise = postsService.update(data, id);
    return {
        type: postsConstants.UPDATEPOST_SUCCES,
        payload: promise
    };
}

