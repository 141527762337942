import React, {Component} from 'react';

import Layout from "../../../../_components/Layout/Layout";
import {AddPostHeader} from "./AddPostHeader";
import AddPostForm from "./AddPostForm";


class AddPost extends Component {

    render() {
        return (
            <Layout>
                <div className="col-lg-10">
                    <AddPostHeader/>
                    <AddPostForm/>

                </div>
            </Layout>
        );
    }
}


export default (AddPost);





