import {fetchAPI, fetchAPIFormData} from "./API"

export const postsService = {
    getAll,
    create,
    getPost,
    update,
};

function getAll() {
    return fetchAPI("posts" , "GET")
}

function create(data) {
    let formData = new FormData();
    formData.append('title', data.title );
    formData.append('content', data.content);
    formData.append('subtitle1',data.subtitle1);
    formData.append('subtitle2',data.subtitle2);
    formData.append('subtitle3',data.subtitle3);
    formData.append('subtitle4',data.subtitle4);
    formData.append('subtitle5',data.subtitle5);
    formData.append('paraf1', data.paraf1);
    formData.append('paraf2', data.paraf2);
    formData.append('paraf3', data.paraf3);
    formData.append('paraf4', data.paraf4);
    formData.append('paraf5', data.paraf5);
    formData.append('meta_description', data.meta_description);
    formData.append('famille_id',data.famille_id);
    formData.append('image', data.image);
    return fetchAPIFormData("posts" , "POST", formData)
}

function update(data, id) {
    let formData = new FormData();
    formData.append('title', data.title );
    formData.append('content', data.content);
    formData.append('subtitle1',data.subtitle1);
    formData.append('subtitle2',data.subtitle2);
    formData.append('subtitle3',data.subtitle3);
    formData.append('subtitle4',data.subtitle4);
    formData.append('subtitle5',data.subtitle5);
    formData.append('paraf1', data.paraf1);
    formData.append('paraf2', data.paraf2);
    formData.append('paraf3', data.paraf3);
    formData.append('paraf4', data.paraf4);
    formData.append('paraf5', data.paraf5);
    formData.append('meta_description', data.meta_description);
    formData.append('famille_id',data.famille_id);
    formData.append('image', data.image);
    return fetchAPIFormData("posts/"+id , "PUT", formData)
}

function getPost(id) {
    return fetchAPI("posts/"+id , "GET")
}
