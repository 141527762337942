import { createStore, applyMiddleware } from 'redux';
import reduxPromise from 'redux-promise';

import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from "../_reducers"

const loggerMiddleware = createLogger();

export const store = createStore(
    rootReducer,
    {},
    applyMiddleware(
        reduxPromise,
        thunkMiddleware,
        loggerMiddleware
    )
);
