export const userService = {
    login,
    logout
};

function login(email, password) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ user: {email, password }})
    };

    return fetch(`${process.env.API_AUTH}/login`, requestOptions)
        .then(handleResponse)
        .then(user => {

            localStorage.setItem('user', JSON.stringify(user));

            return user;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function handleResponse(response) {
    const token = response.headers.get('Authorization');
    return response.text()
        .then(text => {
            const data = text && JSON.parse(text);
            if (!response.ok) {
                if (response.status === 401) {
                    // auto logout if 401 response returned from api
                    logout();
                    // eslint-disable-next-line no-restricted-globals
                    location.reload(true);
                }
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
            data.token = token;
            return data;
        });
}
