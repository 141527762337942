import React, {Component} from 'react';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits, connectHits } from 'react-instantsearch-dom';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Hdits = ({ hits }) => {

    function handledeIcon(bool){
        if(bool) {
            return (<span className="icon green"><FontAwesomeIcon icon="check-circle" /></span>)
        }
        return (<span className="icon red"><FontAwesomeIcon icon="minus-circle" /></span>)
    }
    return (
        <div className="row results">
            {hits.map(hit => (
                <div className="col-4" key={hit.objectID}>
                    <div className="card result">
                        <h5><Link to={"/geo/"+hit.objectID}>{hit.code_postal} - {hit.nom}</Link></h5>
                        <p>
                            Description : {handledeIcon(hit.description)}<br/>
                            Département : {handledeIcon(hit.dpt)}
                        </p>
                    </div>
                </div>
            ))}
        </div>
    )
};



const CustomHits = connectHits(Hdits);

class GeosSearch extends Component {

    render(){
        const searchClient = algoliasearch(
            process.env.ALGOLIA_ID,
            process.env.ALGOLIA_APIKEY
        );
        return(
            <div className="row justify-content-md-center">
                <InstantSearch
                    indexName="Geo"
                    searchClient={searchClient}
                    root={{
                        Root: 'div',
                        props: {
                            className: 'col-10',
                        },
                    }}
                >
                    <SearchBox
                        className="search"
                        autoFocus
                    />
                    <CustomHits
                        hitComponent={CustomHits}
                    />
                </InstantSearch>
            </div>
        )
    }
}

export default GeosSearch;

