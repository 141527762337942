import React, {Component} from 'react';

import Layout from "../../../../_components/Layout/Layout";
import {connect} from "react-redux";
import { bindActionCreators } from 'redux';
import {getPost} from "../../../../_actions";
import {ShowPostHeader} from "./ShowPostHeader";
import Placeholder from "../../../../../assets/images/placeholder.png"


class ShowPost extends Component {

    componentDidMount(){
        if (!this.props.post) {
            this.props.getPost(this.props.match.params.id);
        }
    }

    

    render() {
        if(!this.props.post){
            return (<p>Chargmement ... </p>)
        }
        return (
            <Layout>
                <div className="col-lg-10">
                    <ShowPostHeader
                        title={this.props.post.title}
                        id={this.props.post.id}
                    />
                    <hr/>
                    <br/>
                    <div className="row justify-content-md-center">
                        <div className="col-5">
                            <img src={this.props.post.image.url? this.props.post.image.url : Placeholder } alt="" width='300' height='300' className="imagePost"/>
                        </div>
                        <div className="col-5">
                            <h3>Information sur l'article : </h3>
                            <ul>
                                <li><strong>Slug :</strong> {this.props.post.slug}</li>
                                <li><strong>Famille :</strong> {this.props.post.famille.name}</li>
                                <li><strong>Sujet :</strong> {this.props.post.famille.sujet.name}</li>
                                <li><strong>Lien :</strong> <a target="_blank" href={"https://notre-notaire.fr/sujets/"+this.props.post.famille.sujet.slug+"/familles/"+this.props.post.famille.slug+"/posts/"+this.props.post.slug}> Article </a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row justify-content-md-center">
                        <div className="col-10">
                            <hr/>
                            <br/>
                            <h3>Chapeau : </h3>
                            <hr/>
                            <p dangerouslySetInnerHTML={{__html: this.props.post.content}} />
                            <br/>
                            <h3>Contenue :</h3>
                            <hr/>
                            <h4>{this.props.post.subtitle1}</h4>
                            <p dangerouslySetInnerHTML={{__html: this.props.post.paraf1}} />
                            <h4>{this.props.post.subtitle2}</h4>
                            <p dangerouslySetInnerHTML={{__html: this.props.post.paraf2}} />
                            <h4>{this.props.post.subtitle3}</h4>
                            <p dangerouslySetInnerHTML={{__html: this.props.post.paraf3}} />
                            <h4>{this.props.post.subtitle4}</h4>
                            <p dangerouslySetInnerHTML={{__html: this.props.post.paraf4}} />
                            <h4>{this.props.post.subtitle5}</h4>
                            <p dangerouslySetInnerHTML={{__html: this.props.post.paraf5}} />
                            <br/>
                            <h3>Meta description : </h3>
                            <hr/>
                            <p dangerouslySetInnerHTML={{__html: this.props.post.meta_description}} />
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

function mapStateToProps(state, ownProps) {
    const id = parseInt(ownProps.match.params.id, 10);
    const post = state.posts.find(post => post.id === id);
    return {post};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getPost }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(ShowPost);





