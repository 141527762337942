import React, {Component} from 'react';
import Layout from "../../_components/Layout/Layout";
import {GeosHeader} from "./GeosHeader";
import GeosTable from "./GeosTable";
import GeosSearch from "./GeosSearch";


class Geos extends Component {


    render() {
        return (
            <Layout>
                <div className="col-lg-10">
                    <GeosHeader/>
                    <hr/>
                    <GeosSearch/>
                    <GeosTable/>
                </div>
            </Layout>
        );
    }
}


export default Geos;





