import React, {Component} from 'react';
import Layout from "../../_components/Layout/Layout";
import {geosService} from "../../_services";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


class Stats extends Component {

    constructor(props){
        super(props);

        this.state = {
            geosTop20: ''
        };
        console.log(this.state.geosTop20)
    }

    componentDidMount() {
        console.log('MOO');
       geosService.getStatGeoTop20().then(data => {
           this.setState({geosTop20: data});
           console.log(this.state.geosTop20)
       })
    }

    render() {
        if(!this.state.geosTop20){
            return (<p>Chargement... </p>)
        }
        return (
            <Layout>
                <div className="col-md-10 col-md-offset-3">
                    <h2 className="mt-3">Voici le TOP 40 des villes avec le plus de notaire sans description :</h2>
                    <ol className="row top20">
                        {this.state.geosTop20.map(geo =>
                            <div className="col-3" key={geo.id}>
                               <div className="card result">
                                   <div className="row">
                                       <div className="col-10">
                                           <h5><FontAwesomeIcon icon="home" />: <Link to={"edit/geo/"+geo.id}>{geo.nom}</Link></h5>
                                           <p><FontAwesomeIcon icon="star" />: {geo.count}</p>
                                       </div>
                                       <div className="col-2"><li>&nbsp;</li></div>
                                   </div>
                               </div>
                            </div>
                        )}
                    </ol>
                </div>
            </Layout>
        );
    }
}


export default Stats;





