import React, {Component} from 'react';
import {Link} from "react-router-dom";


class PostTable extends Component {


    render() {
        if(this.props.data.length === 0){
            return <p>Loading...</p>
        }
        return (
            <div className="row justify-content-md-center">
                <div className="col-md-10">
                    <table className="table table-borderless">
                        <thead>
                        <tr>
                            <th scope="col">Title</th>
                            <th scope="col">Famille</th>
                            <th scope="col">Sujet</th>
                            <th scope="col"> </th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.props.data.map((post) =>
                            <tr key={post.id}>
                                <th scope="row"><Link to={"post/"+post.id}>{post.title}</Link></th>
                                <td>{post.famille.name}</td>
                                <td>{post.famille.sujet.name}</td>
                                <td><Link to={"edit/post/"+post.id}><button className="btn btn-primary">Edit</button></Link></td>
                            </tr>
                        )}
                        </tbody>
                    </table>

                </div>
            </div>


        );
    }
}


export default PostTable;





