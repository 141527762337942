import React, {Component} from 'react';

import {connect} from 'react-redux';
import {getPosts} from '../../_actions';
import {bindActionCreators} from 'redux';
import Layout from "../../_components/Layout/Layout";

import {PostsHeader} from "./PostsHeader";
import PostTable from "./PostTable";

class Posts extends Component {

    componentDidMount() {
        this.props.getPosts()
    }

    render() {
        if(this.props.posts.length === 1){
            this.props.getPosts();
            return (<p>Chargement...</p>)
        }
        return (
            <Layout>
                <div className="col-lg-10">
                    <PostsHeader/>
                    <hr/>
                    <PostTable data={this.props.posts}/>
                </div>
            </Layout>
        );
    }
}

function mapStateToProps(state) {
    return {
        posts: state.posts
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({getPosts}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Posts);





