import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { categories } from "./categories.reducer";
import { posts } from "./posts.reducer";
import { geos } from "./geos.reducer";


const rootReducer = combineReducers({
    authentication,
    categories,
    posts,
    geos
});

export default rootReducer;
