import React from 'react';
import{Link} from "react-router-dom";

export const PostsHeader = (props) => {
    return(
        <div className="header row">
            <div className="col-lg-6">
                <h2>Liste des Articles</h2>
            </div>
            <div className="col-lg-6 text-right">
                <Link to="/add/post"><button className="btn btn-primary">Ajouter un article</button></Link>
            </div>
        </div>
    )
};
