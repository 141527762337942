import React, {Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import{Link,
    useRouteMatch
} from "react-router-dom";
import Logo from '../../../../assets/images/icon_white_svg.svg'


class NavBar extends Component{
    render(){
        return(
            <div className="col-lg-2 NavBar">
                <Logo alt="" className='logo'/>
                <h2 className="text-center">Rédacteur</h2>
                <div className="links">
                    <OldSchoolMenuLink
                        activeOnlyWhenExact={true}
                        links={["/"]}
                        to="/"
                        label="Statistique"
                        icon = "chart-bar"
                    />
                    <OldSchoolMenuLink
                        activeOnlyWhenExact={true}
                        links={["/posts","/add/post","/edit/post/:id","/post/:id"]}
                        to="/posts"
                        label="Articles"
                        icon = "edit"
                    />
                    <OldSchoolMenuLink
                        activeOnlyWhenExact={true}
                        links={["/Geos","/edit/geo/:id","/geo/:id"]}
                        to="/Geos"
                        label="Géolocalisation"
                        icon = "map-marker-alt"
                    />
                </div>
            </div>
        )
    }

}

function OldSchoolMenuLink({ label, to, links, activeOnlyWhenExact, icon}) {
    let match = useRouteMatch({
        path: links,
        exact: activeOnlyWhenExact
    });

    return (
        <div className={match ? "active nav-link" : "nav-link"}>

            <Link to={to}>
                <FontAwesomeIcon icon={icon} /> {label}
            </Link>
        </div>
    );
}

export default NavBar;
