import {geosConstants} from '../_constants';

export function geos(state = [], action) {
    switch (action.type) {
        case geosConstants.GETGEO_SUCCES:
            return [action.payload];
        default:
            return state;
    }
}
